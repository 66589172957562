@import 'tailwindcss/base';
/*@import './prism.css';*/
@import './torchlight.css';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#__next {
	min-height: 100%;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}

	.text-wrap {
		text-wrap: wrap;
	}
}

@layer components {
	.giscus {
		@apply bg-gray-50/60 backdrop-blur-2xl p-8 rounded-2xl border-2;
	}
}