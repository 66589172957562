pre {
	@apply overflow-x-auto;
}

pre code.torchlight {
	@apply block py-2 min-w-max text-sm h-full;
}

pre code.torchlight .line {
	@apply px-4;
}

pre code.torchlight .line-number,
pre code.torchlight .summary-caret {
	@apply mr-4;
}

/*
  Blur and dim the lines that don't have the `.line-focus` class,
  but are within a code block that contains any focus lines.
*/
.torchlight.has-focus-lines .line:not(.line-focus) {
	transition: filter 0.35s, opacity 0.35s;
	filter:     blur(.095rem);
	opacity:    .65;
}

/*
  When the code block is hovered, bring all the lines into focus.
*/
.torchlight.has-focus-lines:hover .line:not(.line-focus) {
	filter:  blur(0px);
	opacity: 1;
}

.torchlight-collection pre {
	@apply flex-1 h-full;
}